<template>
  <v-container
    fluid
    class="pa-0"
  >
    <v-card>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-text-field
          v-model="options.search"
          rounded
          dense
          outlined
          hide-details
          label="搜索"
          placeholder="按分类名称搜索"
          :append-icon="icons.mdiMagnify"
          class="flex-grow-0"
          @click:append="getDesserts"
        />
        <v-spacer></v-spacer>
        <v-btn
          v-permission="['50001']"
          color="primary"
          dark
          @click="newItemOpenDialog"
        >
          添加分类
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="desserts"
        :items-per-page.sync="options.pageSize"
        :page.sync="options.page"
        :server-items-length="totalDesserts"
        :loading="loading"
        :disable-sort="true"
        :footer-props="{itemsPerPageOptions: [10, 15, 20, 50, 100]}"
        @update:items-per-page="getDesserts"
        @update:page="getDesserts"
      >
        <template v-slot:item.image="{ item }">
          <v-img
            :src="item.image"
            max-height="32"
            max-width="32"
          ></v-img>
        </template>
        <template v-slot:item.created_at="{ item }">
          {{ item.created_at | dateFormat }}
        </template>
        <template v-slot:item.updated_at="{ item }">
          {{ item.updated_at | dateFormat }}
        </template>
        <template v-slot:item.status="{ item }">
          <v-chip
            :color="item.status ? 'success' : 'error'"
            dark
          >
            {{ item.status ? '启用' : '禁用' }}
          </v-chip>
        </template>
        <template
          v-slot:item.actions="{ item }"
        >
          <v-btn
            v-permission="['50002']"
            icon
            @click="editBtnClick(item)"
          >
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
          <v-btn
            v-permission="['50003']"
            icon
            @click="deleteBtnClick(item)"
          >
            <v-icon>{{ icons.mdiDelete }}</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog
      v-model="editDialog"
      max-width="500"
      transition="dialog-bottom-transition"
    >
      <v-card
        class="pa-sm-10"
      >
        <v-card-title class="justify-center text-h5">
          {{ !rowId ? '新增分类' : '编辑分类' }}
        </v-card-title>
        <v-card-text class="text-center mt-n2">
          {{ !rowId ? '创建新的商品分类' : '修改当前商品分类信息' }}
        </v-card-text>
        <v-card-text class="mt-5">
          <v-form
            ref="categoryFormRef"
            lazy-validation
          >
            <v-text-field
              v-model="row.name"
              label="分类名"
              :rules="[rules.required, rules.counter]"
              validate-on-blur
              counter
              maxlength="20"
              dense
              outlined
            ></v-text-field>
            <v-file-input
              v-model="row.image"
              accept="image/*"
              hint="图片大小不得超过1M，建议使用128*128的png图片"
              label="ICON图标"
              prepend-icon=""
              :append-icon="icons.mdiFolderImage"
              :rules="rowId ? [] : [rules.required, rules.imageType, rules.imageSize]"
              persistent-hint
              show-size
              outlined
              dense
              @change="selectFile"
            ></v-file-input>
            <v-img
              lazy-src="https://dummyimage.com/128x128/f5f5f5/9e9e9e.png"
              max-height="128"
              max-width="128"
              :src="previewImage || 'https://dummyimage.com/128x128/f5f5f5/9e9e9e.png'"
              class="mb-3"
            ></v-img>
            <v-select
              v-model="row.status"
              :items="categoryStatus"
              hint="禁用分类后，分类下的所有商品将对商户不可见."
              persistent-hint
              required
              outlined
              dense
              label="分类状态"
            ></v-select>
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-center mt-3">
          <v-btn
            color="success"
            dark
            @click="editDialogSubmit"
          >
            保存
          </v-btn>
          <v-btn
            outlined
            @click="cancelDialog"
          >
            取消
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="deleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          确定要删除这条数据吗?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            @click="cancelDelete"
          >
            取消
          </v-btn>
          <v-btn
            color="success"
            @click="deleteItem"
          >
            确认
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import {
  getCategories,
  addCategory,
  updateCategory,
  deleteCategory,
} from '@/api/goods'
import {
  mdiMagnify, mdiClose, mdiDelete, mdiPencil, mdiFolderImage,
} from '@mdi/js'

export default {
  name: 'Categories',
  data() {
    return {
      desserts: [],
      totalDesserts: 0,
      previewImage: '',
      editDialog: false,
      deleteDialog: false,
      rowId: null,
      row: {
        name: '',
        image: null,
        status: 1,
      },
      categoryStatus: [
        {
          text: '启用',
          value: 1,
        },
        {
          text: '禁用',
          value: 0,
        },
      ],
      loading: true,
      rules: {
        required: value => !!value || '必填.',
        counter: value => (value && value.length <= 20) || '最大20个字符',
        imageType: value => (value && /^image\/*/.test(value.type)) || '请上传正确的图片文件',
        imageSize: value => (value && value.size < 1000000) || '请上传1MB以内的图片文件',
      },
      headers: [
        { text: 'ICON', value: 'image' },
        { text: '分类名称', value: 'name' },
        { text: '商品数量', value: 'goods_count' },
        { text: '创建时间', value: 'created_at' },
        { text: '更新时间', value: 'updated_at' },
        { text: '状态', value: 'status' },
        { text: '操作', align: 'center', value: 'actions' },
      ],
      icons: {
        mdiMagnify,
        mdiClose,
        mdiDelete,
        mdiPencil,
        mdiFolderImage,
      },
      options: {
        page: 1,
        pageSize: 10,
        search: '',
      },
    }
  },

  created() {
    this.getDesserts()
  },

  methods: {
    getDesserts() {
      this.loading = true
      getCategories(this.options).then(response => {
        this.desserts = response.data.results
        this.totalDesserts = response.data.count
        this.loading = false
      })
    },
    async editBtnClick(item) {
      this.rowId = item.id
      this.row = Object.assign(this.row, item)
      this.previewImage = item.image
      this.row.image = null
      this.editDialog = true
    },
    deleteBtnClick(item) {
      this.rowId = item.id
      this.deleteDialog = true
    },
    cancelDelete() {
      this.rowId = null
      this.deleteDialog = false
    },
    async deleteItem() {
      await deleteCategory(this.rowId)
      this.rowId = null
      this.deleteDialog = false
      this.$toast.success('删除成功')
      this.options.page = 1
      this.getDesserts()
    },
    async newItemOpenDialog() {
      this.row = {
        name: '',
        image: null,
        status: 1,
      }
      this.rowId = null
      this.editDialog = true
    },
    async editDialogSubmit() {
      if (!this.$refs.categoryFormRef.validate()) return
      const formData = new FormData()
      formData.append('name', this.row.name)
      formData.append('status', this.row.status)
      if (this.row.image) {
        formData.append('image', this.row.image)
      }
      if (this.rowId) {
        await updateCategory(this.rowId, formData)
        this.$toast.success('更新成功')
      } else {
        await addCategory(formData)
        this.$toast.success('创建成功')
      }
      this.getDesserts()
      this.editDialog = false
    },
    selectFile(file) {
      if (!file) {
        this.previewImage = null
        return
      }
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = e => {
        this.previewImage = e.target.result
      }
    },

    cancelDialog() {
      this.$refs.categoryFormRef.reset()
      this.previewImage = null
      this.editDialog = false
    },
  },
}
</script>
